<template>
    <div class="db-modal robots-setting">
        <div :class="`content ${this.modalMode == -1 ? 'small' : ''}`">
            <div class="wrapper">
                <h3 v-if="this.modalMode != -1">{{ this.modalMode ? '編輯機器人設定' : '新增機器人' }}</h3>
                <h3 v-if="this.modalMode == -1">選擇機器人種類</h3>
                <div class="choose-exchange" v-if="this.modalMode == -1">
                    <div class="exchange" @click="openModal(0, 0);">
                        <img src="@/assets/images/robot-types/loan.svg" alt="" class="icon">
                        <h5>放貸</h5>
                    </div>
                    <div class="exchange" @click="openModal(0, 1);">
                        <img src="@/assets/images/robot-types/balance.svg" alt="" class="icon">
                        <h5>資金費率套利</h5>
                    </div>
                </div>
                <div class="setting-form loan" v-if="this.modalMode > -1 && !this.modalRobotType">
                    <InputUnit title="機器人暱稱" type="text" v-model="lendingData.name" placeholder="請輸入機器人暱稱" :hasBtn="false" />
                    <InputUnit title="交易標的" type="select" :selectOptions="currencyList" v-model="lendingData.params.market" :disabled="!!this.modalMode"/>
                    <div class="help-area">
                        <h5 class="help-title">說明</h5>
                        <p class="help">填入自設暱稱、目標年利率、最低保留金額、借貸天數，隨後點擊右下角「新增機器人」即設立完成
                        <br>* 借貸天數：每次放貸的週期與利率收益直接相關 (建議低利可以做短期，高利可以放長期)
                        <br>* 最低保留金額：默認設置為0，資金利用率為100%</p>
                    </div>
                    <InputUnit title="目標年利率(%)" type="number" value="0.0" v-model="lendingData.params.min_rate" :minVal="0" placeholder="請輸入最低利率" :hasBtn="false" />
                    <InputUnit :title="`最低保留金額(${lendingData.params.market})`" type="number" value="0.0" v-model="lendingData.params.min_remain" :minVal="0" placeholder="請輸入保留金額" :hasBtn="false" />
                    <InputUnit title="借貸天數" type="select" :selectOptions="lendingPeriodOptions" v-model="lendingData.params.period" :minVal="0" placeholder="請輸入單筆上限" :hasBtn="false" />
                    <InputUnit title="AI Level" type="number" value="10" :minVal="0" :disabled="true" tooltip="AI Level說明" :hasBtn="false" />
                </div>
                <div class="setting-form" v-if="this.modalMode > -1 && this.modalRobotType">
                    <InputUnit title="機器人暱稱" type="text" placeholder="請輸入機器人暱稱" v-model="arbData.name" :hasBtn="false" />
                    <InputUnit title="FTX子帳號" type="text" :value="this.userInfo ? (this.userInfo.ftx_subaccount != '' ? this.userInfo.ftx_subaccount : '無') : '無'" :disabled="true" tooltip="若要設定子帳號，請至設定頁面" :hasBtn="false"  />
                    <div class="help-area">
                        <h5 class="help-title">說明</h5>
                        <p class="help">說明文字說明文字說明文字說明文字說明文字說明文字說明文字說明文字說明文字說明文字</p>
                    </div>
                    <InputUnit title="交易標的" type="select" :selectOptions="currencyList" v-model="arbData.params.market" :disabled="!!(this.modalMode && !this.arbCanEdit)"/>
                    <InputUnit title="計價幣別" type="select" :selectOptions="valuationList" v-model="arbData.params.currency" :disabled="!!(this.modalMode && !this.arbCanEdit)"/>
                    <div class="rate-setting pos">
                        <h3>資金費率設定</h3>
                        <InputUnit title="進場金額數量" type="number" :minVal="0" placeholder="請輸入進場金額數量" v-model="arbData.params.cap" :disabled="!!(this.modalMode && !this.arbCanEdit)" :hasBtn="false" />
                        <InputUnit title="進出場容許誤差" type="number" :minVal="0.0" value="0.0" placeholder="請輸入進出場容許誤差" v-model="arbData.params.spread_limit" :hasBtn="false" />
                        <InputUnit title="資金費率進場閥值(%)" type="number" value="0.0" placeholder="請輸入資金費率進場閥值" v-model="arbData.params.open_threshold" :hasBtn="false" />
                        <InputUnit title="槓桿倍數" type="number" :value="this.userInfo ? (this.userInfo.leverage ? this.userInfo.leverage : '1.0') : '1.0'" :disabled="true" tooltip="槓桿倍數說明" :hasBtn="false" />
                    </div>
                </div>
            </div>
            <div v-if="this.modalMode > -1" class="btn-container">
                <button class="blue" @click="closeModal(0)">取消</button>
                <button class="light" @click="closeModal(1)">{{ this.modalMode ? '儲存設定' : '新增機器人' }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import InputUnit from '@/components/InputUnit'
import {apiAddBot, apiEditBot} from '@/apis/robot.js'
import {apiGetInfo} from '@/apis/account.js'
import $ from 'jquery'

export default {
    name: 'RobotSettingModal',
    components: {
        InputUnit,
    },
    data (){
        return {
            arbData: {
                name: "",
                type: "fr_arb",
                params: {
                    market: "",
                    currency: "",
                    open_threshold: "0.0",
                    spread_limit: "0.0",
                    cap: "0"
                }
            },
            lendingData: {
                name: "",
                type: "lending",
                params: {
                    market: "",
                    min_rate: "0",
                    min_remain: "0",
                    ai_level: 10,
                    period: 2
                }
            },
            robot_id: "",
            modalMode: {
                type: Number,
                default: -1
            },
            modalRobotType: {
                type: Number,
                default: 0
            },
            lendingPeriodOptions: [{
                title: "2天",
                value: 2,
            }, {
                title: "7天",
                value: 7,
            }, {
                title: "30天",
                value: 30,
            }, {
                title: "60天",
                value: 60,
            }, {
                title: "120天",
                value: 120,
            }],
            lendingMinRateChanged: false,
            arbCanEdit: true,
            valuationList: [{
                title: "USD",
                value: "USD"
            }, {
                title: "USDT",
                value: "USDT"
            }],
            userInfo: {}
        }
    },
    props: {
        platformList: {
            type: Array,
            default: () => [{
                title: "Bitfinex",
                value: "bitfinex"
            }, {
                title: "FTX",
                value: "ftx"
            }]
        },
        currencyList: {
            type: Array,
            default: () => []
        },
    },
    watch: {
        watchLendingMr: {
            handler(val, oldVal) {
                if(val != oldVal){
                    if(val < 15){
                        this.lendingData.params.period = 2
                    }else if(val < 20){
                        this.lendingData.params.period = 7
                    }else if(val < 30){
                        this.lendingData.params.period = 30
                    }else if(val < 50){
                        this.lendingData.params.period = 60
                    }else{
                        this.lendingData.params.period = 120
                    }
                }
                console.log(val)
                console.log(oldVal)
            }
        }
    },
    methods: {
        openModal(mode=-1, type=0, defaultData={}, valuationAva=[true, true]){ // usd, usdt
            // reset
            this.$data.valuationList = this.$options.data().valuationList;
            this.$data.arbData = this.$options.data().arbData;
            this.$data.lendingData = this.$options.data().lendingData;
            this.getInfo()

            this.modalMode = mode
            this.modalRobotType = type
            this.valuationList = this.valuationList.filter(ele => valuationAva[ele.value == 'USD' ? 0 : 1])
            if(type){
                this.merge(this.arbData, defaultData)
                if(this.arbData.params.currency == "")this.arbData.params.currency = this.valuationList[0].value
                this.arbCanEdit = true
                if(defaultData.records && defaultData.records.filter(ele => ele.position_type == 'open').length)this.arbCanEdit = false;
            }else{
                this.merge(this.lendingData, defaultData)
            }
            if(defaultData && defaultData._id)this.robot_id = defaultData._id;
            if(this.lendingData.params.market.startsWith('f'))this.lendingData.params.market = this.lendingData.params.market.substring(1)
            $('.db-modal.robots-setting').fadeIn(300).css('display', 'flex');
        },
        robotFormValidation(){
            if(this.modalRobotType){
                if(this.arbData.name == ""){
                    this.$toast.error("請輸入機器人暱稱", {
                        timeout: 2000
                    });
                    return false;
                }else if(this.arbData.params.market == ""){
                    this.$toast.error("請選擇交易標的", {
                        timeout: 2000
                    });
                    return false;
                }else if(this.arbData.params.currency == ""){
                    this.$toast.error("請選擇計價幣別", {
                        timeout: 2000
                    });
                    return false;
                }else{
                    return true;
                }
            }else{
                if(this.lendingData.name == ""){
                    this.$toast.error("請輸入機器人暱稱", {
                        timeout: 2000
                    });
                    return false;
                }else if(this.lendingData.params.market == ""){
                    this.$toast.error("請選擇交易標的", {
                        timeout: 2000
                    });
                    return false;
                }else if(this.lendingData.params.min_rate === ""){
                    this.$toast.error("請輸入目標年利率", {
                        timeout: 2000
                    });
                    return false;
                }else if(this.lendingData.params.min_remain === ""){
                    this.$toast.error("請輸入保留金額", {
                        timeout: 2000
                    });
                    return false;
                }else if(this.lendingData.params.period == ""){
                    this.$toast.error("請輸入借貸天數", {
                        timeout: 2000
                    });
                    return false;
                }else{
                    return true;
                }
            }
        },
        closeModal(mode=1){ //Cancel = 0, Save = 1
            if(mode){
                if(this.modalRobotType){
                    if(this.robotFormValidation()){
                        if(this.modalMode == 1){
                            let tmp = $.extend(true, {}, this.arbData, {update_time: Date.now()});
                            console.log(tmp)
                            apiEditBot({
                                b_id: this.robot_id,
                                data: tmp
                            }).then(res => {
                                if(res.data.status){
                                    this.$toast.success('更新成功！', {
                                        timeout: 2000
                                    });
                                    window.location = "/dashboard/robots"
                                    $('.db-modal.robots-setting').fadeOut(300);
                                }else{
                                    this.$toast.error(res.data.msg, {
                                        timeout: 2000
                                    });
                                }
                            })
                        }else{
                            apiAddBot(this.arbData).then(res => {
                                if(res.data.status){
                                    this.$toast.success('新增成功！', {
                                        timeout: 2000
                                    });
                                    window.location = "/dashboard/robots"
                                    $('.db-modal.robots-setting').fadeOut(300);
                                }else{
                                    this.$toast.error(res.data.msg, {
                                        timeout: 2000
                                    });
                                }
                            })
                        }
                    }
                }else{
                    if(this.robotFormValidation()){
                        if(this.modalMode == 1){
                            let tmp = $.extend(true, {}, this.lendingData, {update_time: Date.now()});
                            tmp.params.market = `f${tmp.params.market}`
                            console.log(tmp)
                            apiEditBot({
                                b_id: this.robot_id,
                                data: tmp
                            }).then(res => {
                                if(res.data.status){
                                    this.$toast.success('更新成功！', {
                                        timeout: 2000
                                    });
                                    window.location = "/dashboard/robots"
                                    $('.db-modal.robots-setting').fadeOut(300);
                                }else{
                                    this.$toast.error(res.data.msg, {
                                        timeout: 2000
                                    });
                                }
                            })
                        }else{
                            let tmp = $.extend(true, {}, this.lendingData);
                            this.merge(tmp, {params: {market: `f${this.lendingData.params.market}`}})
                            apiAddBot(tmp).then(res => {
                                if(res.data.status){
                                    this.$toast.success('新增成功！', {
                                        timeout: 2000
                                    });
                                    window.location = "/dashboard/robots"
                                    $('.db-modal.robots-setting').fadeOut(300);
                                }else{
                                    this.$toast.error(res.data.msg, {
                                        timeout: 2000
                                    });
                                }
                            })
                        }
                    }
                }
            }else{
                $('.db-modal.robots-setting').fadeOut(300);
            }
        }
        , merge(oldObj, newObj){
            let tmp = $.extend(true, {}, newObj);
            Object.keys(tmp).filter(key => key in oldObj).forEach(key => {
                if(this.isObject(oldObj[key]))this.merge(oldObj[key], tmp[key]);
                else{
                    oldObj[key] = tmp[key];
                }
            });
        }
        ,isObject(item){
            return (item && typeof item === 'object' && !Array.isArray(item));
        }
        ,getInfo(){
            apiGetInfo().then(res => {
                if(res.data.status){
                    this.userInfo = res.data.user
                }
            })
        }
    },
    mounted() {
        $('body').on('click', '.db-modal > .content', function(e){
            e.stopPropagation();
        })
    },
    computed:{
        watchLendingMr: function(){
           return this.lendingData.params.min_rate
        },
    }
}
</script>